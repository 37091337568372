import * as React from "react";

import parse from 'html-react-parser';
import "../components/intervention.css";
import Layout from "../layouts/layout";
import { Link } from "gatsby";
import favicon from "../images/favico.ico";
import { Helmet } from "react-helmet";
//import ball from "../images/anneau-gas.jpg"
import { StaticImage } from "gatsby-plugin-image"


const InterventionPage = (props) => {
    const { content } = props.pageContext;
    const { title } = props.pageContext;
    const { titleMeta } = props.pageContext;
    const { metaDesc } = props.pageContext;
    const { featuredImage } = props.pageContext;
    const { childs } = props.pageContext;
    const { uri } = props.pageContext;
    const { faq } = props.pageContext

    const children = [];
    if (faq.question1 != null) {
        children.push({ question: faq.question1, answer: faq.reponse1 })
    }
    if (faq.question2 != null) {
        children.push({ question: faq.question2, answer: faq.reponse2 })
    }
    if (faq.question3 != null) {
        children.push({ question: faq.question3, answer: faq.reponse3 })
    }
    if (faq.question4 != null) {
        children.push({ question: faq.question4, answer: faq.reponse4 })
    }
    if (faq.question5 != null) {
        children.push({ question: faq.question5, answer: faq.reponse5 })
    }
    if (faq.question6 != null) {
        children.push({ question: faq.question6, answer: faq.reponse6 })
    }
    if (faq.question7 != null) {
        children.push({ question: faq.question7, answer: faq.reponse7 })
    }
    if (faq.question8 != null) {
        children.push({ question: faq.question8, answer: faq.reponse8 })
    }
    if (faq.question9 != null) {
        children.push({ question: faq.question9, answer: faq.reponse9 })
    }
    if (faq.question10 != null) {
        children.push({ question: faq.question10, answer: faq.reponse10 })
    }
    return (
        <Layout>
            <Helmet htmlAttributes={{
                lang: 'fr-FR',
            }}>
                <link rel="icon" type="image/x-icon" href={favicon} />
                <title>{titleMeta}</title>
                <meta name="description" content={metaDesc} />
                <meta name="robots" content="index, follow" />
                <link data-react-helmet="true" rel="canonical" href={"https://www.medica-tour.fr" + uri}></link>
                <script type="application/ld+json">
                    {JSON.stringify(children)}</script>
                    <meta name="msvalidate.01" content="BF6180D2B9843FD051D8456A095B9C38" />
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-TSKFDDEV9E"></script>
                    <script>
      {`
        window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-TSKFDDEV9E');
      `}
    </script>
            </Helmet>
            <div className="container-fluid pt-5 pb-5" style={{
                backgroundImage: `url(${featuredImage})`,
                backgroundSize: "cover",
                objectFit: "contain",
                color: "white",
                backgroundColor: "gray",
                backgroundPosition: "center",
            }}>
                <div className="container intern-page-style">
                    <h1 className="service-title">
                        {title}
                    </h1>
                </div>
            </div>


            <div className="container mt-4 pt-4">
                <div className="row">

                    {childs && childs.map((child, i) => {
              if (child.title == "Augmentation Mammaire en Tunisie") {
                return (

                    <div className="col-sm-12 col-md-3 mt-4 mb-4">
                        <div className="card h-100" >

                            <Link to={child.uri} className="link-interv">
                                <StaticImage src="../images/augmentation-mammaire.webp" className="card-img-top img-fluid" alt={child.title} />
                            </Link>
                            <div className="card-body card-body-intervention   w-100">
                                <Link to={child.uri} className="link-interv">
                                    <span className="text-center card-body-title ">{child.title}</span>
                                </Link>

                            </div>
                        </div>
                    </div>
                )
            }
            if (child.title == "Lifting Mammaire en Tunisie") {
                return (

                    <div className="col-sm-12 col-md-3 mt-4 mb-4">
                        <div className="card h-100" >

                            <Link to={child.uri} className="link-interv">
                                <StaticImage src="../images/lifting-seins.webp" className="card-img-top img-fluid" alt={child.title} />
                            </Link>
                            <div className="card-body card-body-intervention   w-100">
                                <Link to={child.uri} className="link-interv">
                                    <span className="text-center card-body-title ">{child.title}</span>
                                </Link>

                            </div>
                        </div>
                    </div>
                )
            }
            if (child.title == "Lipofilling Mammaire en Tunisie") {
                return (

                    <div className="col-sm-12 col-md-3 mt-4 mb-4">
                        <div className="card h-100" >

                            <Link to={child.uri} className="link-interv">
                                <StaticImage src="../images/lipofilling.webp" className="card-img-top img-fluid" alt={child.title} />
                            </Link>
                            <div className="card-body card-body-intervention   w-100">
                                <Link to={child.uri} className="link-interv">
                                    <span className="text-center card-body-title ">{child.title}</span>
                                </Link>

                            </div>
                        </div>
                    </div>
                )
            }
            if (child.title == "Réduction Mammaire en Tunisie") {
                return (

                    <div className="col-sm-12 col-md-3 mt-4 mb-4">
                        <div className="card h-100" >

                            <Link to={child.uri} className="link-interv">
                                <StaticImage src="../images/reduction.webp" className="card-img-top img-fluid" alt={child.title} />
                            </Link>
                            <div className="card-body card-body-intervention   w-100">
                                <Link to={child.uri} className="link-interv">
                                    <span className="text-center card-body-title ">{child.title}</span>
                                </Link>

                            </div>
                        </div>
                    </div>
                )
            }
            if (child.title == "Gynécomastie en Tunisie : Réduction des Seins Masculins") {
                return (

                    <div className="col-sm-12 col-md-3 mt-4 mb-4">
                        <div className="card h-100" >

                            <Link to={child.uri} className="link-interv">
                                <StaticImage src="../images/Gynecomastie.jpg" className="card-img-top img-fluid" alt={child.title} />
                            </Link>
                            <div className="card-body card-body-intervention   w-100">
                                <Link to={child.uri} className="link-interv">
                                    <span className="text-center card-body-title ">{child.title}</span>
                                </Link>

                            </div>
                        </div>
                    </div>
                )
            }
 if (child.title == "Blépharoplastie Tunisie") {
    return (

        <div className="col-sm-12 col-md-3 mt-4 mb-4">
            <div className="card h-100" >

                <Link to={child.uri} className="link-interv">
                    <StaticImage src="../images/Blepharoplastie-1.webp" className="card-img-top img-fluid" alt={child.title} />
                </Link>
                <div className="card-body card-body-intervention   w-100">
                    <Link to={child.uri} className="link-interv">
                        <span className="text-center card-body-title ">{child.title}</span>
                    </Link>

                </div>
            </div>
        </div>
    )
}
if (child.title == "Genioplastie Tunisie") {
    return (

        <div className="col-sm-12 col-md-3 mt-4 mb-4">
            <div className="card h-100" >

                <Link to={child.uri} className="link-interv">
                    <StaticImage src="../images/Genioplastie.webp" className="card-img-top img-fluid" alt={child.title} />
                </Link>
                <div className="card-body card-body-intervention   w-100">
                    <Link to={child.uri} className="link-interv">
                        <span className="text-center card-body-title ">{child.title}</span>
                    </Link>

                </div>
            </div>
        </div>
    )
}
if (child.title == "Lifting visage Tunisie") {
    return (

        <div className="col-sm-12 col-md-3 mt-4 mb-4">
            <div className="card h-100" >

                <Link to={child.uri} className="link-interv">
                    <StaticImage src="../images/lifiting.webp" className="card-img-top img-fluid" alt={child.title} />
                </Link>
                <div className="card-body card-body-intervention   w-100">
                    <Link to={child.uri} className="link-interv">
                        <span className="text-center card-body-title ">{child.title}</span>
                    </Link>

                </div>
            </div>
        </div>
    )
}
if (child.title == "Mini lifting visage Tunisie") {
    return (

        <div className="col-sm-12 col-md-3 mt-4 mb-4">
            <div className="card h-100" >

                <Link to={child.uri} className="link-interv">
                    <StaticImage src="../images/mini-lifting.webp" className="card-img-top img-fluid" alt={child.title} />
                </Link>
                <div className="card-body card-body-intervention   w-100">
                    <Link to={child.uri} className="link-interv">
                        <span className="text-center card-body-title ">{child.title}</span>
                    </Link>

                </div>
            </div>
        </div>
    )
}
if (child.title == "Otoplastie en Tunisie") {
    return (

        <div className="col-sm-12 col-md-3 mt-4 mb-4">
            <div className="card h-100" >

                <Link to={child.uri} className="link-interv">
                    <StaticImage src="../images/Otoplastie-.webp" className="card-img-top img-fluid" alt={child.title} />
                </Link>
                <div className="card-body card-body-intervention   w-100">
                    <Link to={child.uri} className="link-interv">
                        <span className="text-center card-body-title ">{child.title}</span>
                    </Link>

                </div>
            </div>
        </div>
    )
}
if (child.title == "Rhinoplastie Tunisie") {
    return (

        <div className="col-sm-12 col-md-3 mt-4 mb-4">
            <div className="card h-100" >

                <Link to={child.uri} className="link-interv">
                    <StaticImage src="../images/Rhinoplastie-1.webp" className="card-img-top img-fluid" alt={child.title} />
                </Link>
                <div className="card-body card-body-intervention   w-100">
                    <Link to={child.uri} className="link-interv">
                        <span className="text-center card-body-title ">{child.title}</span>
                    </Link>

                </div>
            </div>
        </div>
    )
}
if (child.title == "Lipofilling visage") {
    return (

        <div className="col-sm-12 col-md-3 mt-4 mb-4">
            <div className="card h-100" >

                <Link to={child.uri} className="link-interv">
                    <StaticImage src="../images/lipofilling-visage-en-tunisie.webp" className="card-img-top img-fluid" alt={child.title} />
                </Link>
                <div className="card-body card-body-intervention   w-100">
                    <Link to={child.uri} className="link-interv">
                        <span className="text-center card-body-title ">{child.title}</span>
                    </Link>

                </div>
            </div>
        </div>
    )
}
 if (child.title == "Abdominoplastie Tunisie") {
    return (

        <div className="col-sm-12 col-md-3 mt-4 mb-4">
            <div className="card h-100" >

                <Link to={child.uri} className="link-interv">
                    <StaticImage src="../images/abdo-1.webp" className="card-img-top img-fluid" alt={child.title} />
                </Link>
                <div className="card-body card-body-intervention   w-100">
                    <Link to={child.uri} className="link-interv">
                        <span className="text-center card-body-title ">{child.title}</span>
                    </Link>

                </div>
            </div>
        </div>
    )
}
if (child.title == "Mini abdominoplastie tunisie") {
    return (

        <div className="col-sm-12 col-md-3 mt-4 mb-4">
            <div className="card h-100" >

                <Link to={child.uri} className="link-interv">
                    <StaticImage src="../images/min-abdo-1.webp" className="card-img-top img-fluid" alt={child.title} />
                </Link>
                <div className="card-body card-body-intervention   w-100">
                    <Link to={child.uri} className="link-interv">
                        <span className="text-center card-body-title ">{child.title}</span>
                    </Link>

                </div>
            </div>
        </div>
    )
}
if (child.title == "Bodylift Tunisie") {
    return (

        <div className="col-sm-12 col-md-3 mt-4 mb-4">
            <div className="card h-100" >

                <Link to={child.uri} className="link-interv">
                    <StaticImage src="../images/body-lift-1.webp" className="card-img-top img-fluid" alt={child.title} />
                </Link>
                <div className="card-body card-body-intervention   w-100">
                    <Link to={child.uri} className="link-interv">
                        <span className="text-center card-body-title ">{child.title}</span>
                    </Link>

                </div>
            </div>
        </div>
    )
}
if (child.title == "Lifting des Bras en Tunisie") {
    return (

        <div className="col-sm-12 col-md-3 mt-4 mb-4">
            <div className="card h-100" >

                <Link to={child.uri} className="link-interv">
                    <StaticImage src="../images/lifting-bras-women-1.webp" className="card-img-top img-fluid" alt={child.title} />
                </Link>
                <div className="card-body card-body-intervention   w-100">
                    <Link to={child.uri} className="link-interv">
                        <span className="text-center card-body-title ">{child.title}</span>
                    </Link>

                </div>
            </div>
        </div>
    )
}
if (child.title == "Lifting des cuisses Tunisie") {
    return (

        <div className="col-sm-12 col-md-3 mt-4 mb-4">
            <div className="card h-100" >

                <Link to={child.uri} className="link-interv">
                    <StaticImage src="../images/lifting-cuisse-2.webp" className="card-img-top img-fluid" alt={child.title} />
                </Link>
                <div className="card-body card-body-intervention   w-100">
                    <Link to={child.uri} className="link-interv">
                        <span className="text-center card-body-title ">{child.title}</span>
                    </Link>

                </div>
            </div>
        </div>
    )
}
if (child.title == "Augmentation des mollets Tunisie") {
    return (

        <div className="col-sm-12 col-md-3 mt-4 mb-4">
            <div className="card h-100" >

                <Link to={child.uri} className="link-interv">
                    <StaticImage src="../images/aug-mollet-1.webp" className="card-img-top img-fluid" alt={child.title} />
                </Link>
                <div className="card-body card-body-intervention   w-100">
                    <Link to={child.uri} className="link-interv">
                        <span className="text-center card-body-title ">{child.title}</span>
                    </Link>

                </div>
            </div>
        </div>
    )
}
if (child.title == "Liposuccion Tunisie") {
    return (

        <div className="col-sm-12 col-md-3 mt-4 mb-4">
            <div className="card h-100" >

                <Link to={child.uri} className="link-interv">
                    <StaticImage src="../images/Liposuccion-1.webp" className="card-img-top img-fluid" alt={child.title} />
                </Link>
                <div className="card-body card-body-intervention   w-100">
                    <Link to={child.uri} className="link-interv">
                        <span className="text-center card-body-title ">{child.title}</span>
                    </Link>

                </div>
            </div>
        </div>
    )
}
if (child.title == "Augmentation fesses Tunisie") {
    return (

        <div className="col-sm-12 col-md-3 mt-4 mb-4">
            <div className="card h-100" >

                <Link to={child.uri} className="link-interv">
                    <StaticImage src="../images/aug-fesses-1.webp" className="card-img-top img-fluid" alt={child.title} />
                </Link>
                <div className="card-body card-body-intervention   w-100">
                    <Link to={child.uri} className="link-interv">
                        <span className="text-center card-body-title ">{child.title}</span>
                    </Link>

                </div>
            </div>
        </div>
    )
}
if (child.title == "Lipofilling fessier") {
    return (

        <div className="col-sm-12 col-md-3 mt-4 mb-4">
            <div className="card h-100" >

                <Link to={child.uri} className="link-interv">
                    <StaticImage src="../images/lipolling-fesse-1.webp" className="card-img-top img-fluid" alt={child.title} />
                </Link>
                <div className="card-body card-body-intervention   w-100">
                    <Link to={child.uri} className="link-interv">
                        <span className="text-center card-body-title ">{child.title}</span>
                    </Link>

                </div>
            </div>
        </div>
    )
}
if (child.title == "BBL Tunisie") {
    return (

        <div className="col-sm-12 col-md-3 mt-4 mb-4">
            <div className="card h-100" >

                <Link to={child.uri} className="link-interv">
                    <StaticImage src="../images/bbl-1.webp" className="card-img-top img-fluid" alt={child.title} />
                </Link>
                <div className="card-body card-body-intervention   w-100">
                    <Link to={child.uri} className="link-interv">
                        <span className="text-center card-body-title ">{child.title}</span>
                    </Link>

                </div>
            </div>
        </div>
    )
}
if (child.title == "Lifting des fesses") {
    return (

        <div className="col-sm-12 col-md-3 mt-4 mb-4">
            <div className="card h-100" >

                <Link to={child.uri} className="link-interv">
                    <StaticImage src="../images/by-pass.webp" className="card-img-top img-fluid" alt={child.title} />
                </Link>
                <div className="card-body card-body-intervention   w-100">
                    <Link to={child.uri} className="link-interv">
                        <span className="text-center card-body-title ">{child.title}</span>
                    </Link>

                </div>
            </div>
        </div>
    )
}
                                                    
                                                
                                                                 
                                                                  
                    })

                    }

                </div>
            </div>

            <div className="container-fluid pt-2 blog-single">
                <div className="container ">
                    <div className="row">
                        {content && parse(content)}
                        {(() => {
                                                const options = [];
                                                if (faq.question1 != null) {
                                                    options.push(<h2>FAQ</h2>)
                                                }
                                                const options1 = [];

                                                if (faq.question1 != null) {
                                                    options1.push(
                                                        <div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                                            <h3 itemprop="name">  {faq.question1}</h3>
                                                            <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                                                <div itemprop="text">
                                                                    {faq.reponse1}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                if (faq.question2 != null) {

                                                    options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                                        <h3 itemprop="name">  {faq.question2}</h3>
                                                        <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                                            <div itemprop="text">
                                                                {faq.reponse2}
                                                            </div>
                                                        </div>
                                                    </div>)
                                                }
                                                if (faq.question3 != null) {
                                                    options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                                        <h3 itemprop="name">  {faq.question3}</h3>
                                                        <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                                            <div itemprop="text">
                                                                {faq.reponse3}
                                                            </div>
                                                        </div>
                                                    </div>)
                                                }
                                                if (faq.question4 != null) {
                                                    options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                                        <h3 itemprop="name">  {faq.question4}</h3>
                                                        <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                                            <div itemprop="text">
                                                                {faq.reponse4}
                                                            </div>
                                                        </div>
                                                    </div>)
                                                }
                                                if (faq.question5 != null) {

                                                    options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                                        <h3 itemprop="name">  {faq.question5}</h3>
                                                        <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                                            <div itemprop="text">
                                                                {faq.reponse5}
                                                            </div>
                                                        </div>
                                                    </div>)
                                                }
                                                if (faq.question6 != null) {
                                                    options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                                        <h3 itemprop="name">  {faq.question6}</h3>
                                                        <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                                            <div itemprop="text">
                                                                {faq.reponse6}
                                                            </div>
                                                        </div>
                                                    </div>)
                                                }
                                                if (faq.question7 != null) {
                                                    options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                                        <h3 itemprop="name">  {faq.question7}</h3>
                                                        <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                                            <div itemprop="text">
                                                                {faq.reponse7}
                                                            </div>
                                                        </div>
                                                    </div>)
                                                }
                                                if (faq.question8 != null) {
                                                    options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                                        <h3 itemprop="name">  {faq.question8}</h3>
                                                        <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                                            <div itemprop="text">
                                                                {faq.reponse8}
                                                            </div>
                                                        </div>
                                                    </div>)
                                                }
                                                if (faq.question9 != null) {
                                                    options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                                        <h3 itemprop="name">  {faq.question9}</h3>
                                                        <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                                            <div itemprop="text">
                                                                {faq.reponse9}
                                                            </div>
                                                        </div>
                                                    </div>)
                                                }
                                                if (faq.question10 != null) {
                                                    options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                                        <h3 itemprop="name">  {faq.question10}</h3>
                                                        <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                                            <div itemprop="text">
                                                                {faq.reponse10}
                                                            </div>
                                                        </div>
                                                    </div>)
                                                }
                                                return [options, options1];
                                            })()}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default InterventionPage
